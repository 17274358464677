@import '../../variable';

.nation-piechart {
  // text-align: center;
  @include cardStyle;
  background: $themColorDark;
  margin-top: 0px;

  @include md {
    margin-top: 0;
  }
  .chartbox {
    position: relative;
    width: 100%;
    margin: -10px auto 0;
    padding: 0;
  }

  .group-info {
    margin-top: -10px;
    padding: 0;
    li {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 0;
      + li {
        border-top: 1px solid rgba(255, 255, 255, 0.07);
      }
      strong {
        .nation {
          display: inline-block;
          width: 140px;
        }
        .per {
          font-weight: 500;
          // opacity: 0.6;
          margin-left: 5px;
        }
      }
      .amount {
        opacity: 0.6;
        color: #fff;
      }
    }
  }

  .banner {
    margin-top: 10px;
    font-size: 17px;
    word-break: keep-all;
    background: none;
    padding: 0 !important;

    @include sm {
      font-size: 16px;
    }
    @include xs {
      padding: 14px 5px 14px 14px;
      font-size: 15px;
    }
    a {
      border-radius: 5px;
      background-color: rgba(#3677f0, 0.08);
      border: 1px solid rgba(#3677f0, 0.35);

      padding: 15px 15px 15px 20px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;

      * {
        color: #6b9bf5;
        text-decoration: none;
      }

      &.btn-mask,
      &.btn-korea {
        background-color: rgba(#0fe9f8, 0.03);
        border: 1px solid rgba(#0fe9f8, 0.25);
        * {
          color: #09b7b7;
        }

        svg {
          margin-top: 0;
        }
      }

      div {
        display: flex;
        align-items: center;
        svg {
          margin-top: 3px;
          margin-right: 7px;
          font-size: 19px;
          opacity: 1;
        }
      }

      svg {
        font-size: 25px;
        opacity: 0.5;
      }

      &.btn-korea svg {
        font-size: 24px;
      }
    }
  }
}
